import Slider from 'components/slider';
import { useEffect, useState } from 'react';

const Player3 = (): JSX.Element => {
    const [slided, setSlided] = useState<number>(0);
    const [orient, setOrient] = useState<{ x: number; y: number; z: number; }>({ x: 0, y: 0, z: 0 });

    useEffect(() => {
        window.addEventListener("deviceorientation", (ev: DeviceOrientationEvent) => {
            setOrient({
                x: Math.floor(ev.alpha ?? 0),
                y: Math.floor(ev.beta ?? 0),
                z: Math.floor(ev.gamma ?? 0)
            })
        })
    }, []);

    return (
        <main className="h-full w-full flex justify-center items-center bg-[#181818]">

            <div className="w-10/12 h-full flex justify-evenly items-center flex-col">
                <div className="overflow-x-auto relative w-full max-w-2xl">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="py-3 px-6">Type</th>
                                <th scope="col" className="py-3 px-6">Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">Alpha</th>
                                <td className="py-4 px-6">{orient.x}</td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">Beta</th>
                                <td className="py-4 px-6">{orient.y}</td>
                            </tr>
                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">Gamma</th>
                                <td className="py-4 px-6">{orient.z}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <Slider
                    onChange={(t: number) => setSlided(t)}
                    value={slided}
                    className="max-w-2xl"
                />
            </div>

        </main>
    )
}

export default Player3;