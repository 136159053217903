import './index.css';
import ReactDOM from 'react-dom/client';
import App from 'router';
import { BrowserRouter } from 'react-router-dom';
import ShortCuts from 'components/shortcuts';

const root: ReactDOM.Root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <App />
    <ShortCuts />
  </BrowserRouter>
);