import { Home, Watch, Player1, Player2, Player3 } from './imports';
import { Route, Routes } from 'react-router-dom';

const App = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/watch' element={<Watch />} />
            <Route path='/player1' element={<Player1 />} />
            <Route path='/player2' element={<Player2 />} />
            <Route path='/player3' element={<Player3 />} />
        </Routes>
    )
}

export default App;