import ReactPlayer from 'react-player';
import { useRef, useState } from 'react';

// Icons
import { HiOutlineVolumeUp, HiOutlineVolumeOff } from 'react-icons/hi';
import { IoPlayOutline, IoPauseOutline } from 'react-icons/io5';
import { BsSkipBackward, BsSkipForward } from 'react-icons/bs';
import { FiSettings } from 'react-icons/fi';
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs';

const Player1 = (): JSX.Element => {
	const player = useRef<HTMLSelectElement | null>(null)

	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
	const [volume, setVolume] = useState<number>(1);
	const [lastVolume, setLastVolume] = useState<number>(1);
	const [played, setPlayed] = useState<number>(0);

	return (
		<main className="h-full w-full flex justify-center items-center bg-[#f3e9b8] xs:bg-green-300 sm:bg-slate-300 md:bg-slate-600 lg:bg-red-200 xl:bg-red-500 2xl:bg-red-900">
			<section ref={player} className="w-10/12 max-w-5xl aspect-video bg-slate-400 relative flex justify-center items-center shadow-sm overflow-y-hidden">
				{/*<img
				  src="/street.png"
				  alt=""
				  className='w-full' 
				/>*/}

				<ReactPlayer
					url={"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"}
					muted={volume <= 0.001}
					volume={volume}
					playing={isPlaying}
					onProgress={e => setPlayed(e.played)}
					onEnded={() => setPlayed(1)}
					controls={false}
					playsinline={true}
					width="100%"
					height="100%"
					id="react-player"
				/>

				<div className="h-[160%] w-full absolute flex justify-between items-center z-10 flex-col shadow-inner hover:h-full duration-500 px-px">

					<div className="min-h-[5rem] max-h-[8rem] h-2/5 md:h-1/3 w-full lg:px-4 flex justify-center items-center flex-col overflow-hidden">
						<div className='w-10/12 lg:w-11/12 flex justify-center items-start flex-col'>
							<h1 className="text-[calc(0.7em+1.150vw)] sm:text-lg lg:text-xl font-medium text-slate-200 mb-1 truncate">Create your own Stardew Valley</h1>
							<h5 className="text-[calc(.38rem+1.15vw)] sm:text-xs lg:text-[0.8rem] font-medium text-yellow-400">VLAD GARAM</h5>
						</div>
					</div>

					<div className='absolute w-full h-[78%] bottom-[max(3.5rem,22%)] md:h-4/5 md:bottom-[20%] z-20 text-transparent' onClick={() => setIsPlaying(t => !t)}>text</div>

					<div className="min-h-[3.5rem] h-[22%] md:h-1/5 max-h-[6rem] w-full flex justify-center items-start flex-col">

						<div className="h-[3px] w-full relative flex justify-start items-center">
							<div className="h-full w-full absolute  bg-opacity-40"></div>
							<div className="h-full absolute bg-amber-300 z-10 flex items-center duration-100" style={{ width: `${played * 100}%` }}></div>

							<input
								type="range"
								min={0}
								max={1}
								step="any"
								value={played}
								onChange={e => setPlayed(() => {
									const seeked: number = parseFloat(e.target.value);
									const videoPlayer = document.getElementById("react-player");
									const video: HTMLVideoElement | undefined = videoPlayer?.getElementsByTagName("video")[0];

									if (!video) return played;

									video.currentTime = seeked * (video.duration ? video.duration : 0);
									return seeked;
								})}
								className="cursor-pointer w-full left-0 right-0 duration-300 appearance-none absolute h-full bg-transparent z-20"
							/>
						</div>

						<div className="h-[calc(100%-3px)] w-full backdrop-blur-[10px] flex justify-center items-center">
							<div className="h-full w-1/3 flex justify-start items-center px-7 lg:px-10">
								<div
									className="cursor-pointer mr-5"
									onClick={() => {
										setVolume(vol => {
											if (volume <= 0.001) {
												return lastVolume;
											} else {
												setLastVolume(vol);

												return 0;
											}
										})
									}}
								>
									{
										volume <= 0.001 ?
											<HiOutlineVolumeOff color='white' className="h-5 w-5 lg:h-6 lg:w-6" /> :
											<HiOutlineVolumeUp color='white' className="h-5 w-5 lg:h-6 lg:w-6" />
									}
								</div>
								<div className='h-[3px] w-2/5 rounded-full hidden sm:flex justify-start items-center  relative cursor-pointer'>
									<div className="h-full rounded-full z-10 bg-yellow-400 border border-yellow-400" style={{ width: `${volume * 100}%` }}></div>

									<input
										type="range"
										min={0}
										max={1}
										step="any"
										value={volume}
										onChange={e => setVolume((vol: number) => {
											setLastVolume(vol);
											return parseFloat(e.target.value)
										})}
										className="cursor-pointer w-full left-0 right-0 duration-300 appearance-none absolute h-full bg-transparent z-20"
									/>
								</div>
							</div>
							<div className="h-full w-1/3 flex justify-center items-center">
								<div className="cursor-pointer" onClick={() => console.log("prev")}>
									<BsSkipBackward color="white" className="h-5 w-5 lg:h-6 lg:w-6" />
								</div>

								<div className="cursor-pointer mx-5" onClick={() => setIsPlaying(t => !t)}>
									{
										!isPlaying ?
											<IoPlayOutline color="yellow" className="h-8 w-8 lg:h-9 lg:w-9" /> :
											<IoPauseOutline color="yellow" className="h-8 w-8 lg:h-9 lg:w-9" />
									}
								</div>

								<div className="cursor-pointer" onClick={() => console.log("next")}>
									<BsSkipForward color="white" className="h-5 w-5 lg:h-6 lg:w-6" />
								</div>
							</div>
							<div className="h-full w-1/3 flex justify-end items-center px-7 lg:px-10">
								<div className="cursor-pointer"><FiSettings color="white" className="h-4 w-5 lg:h-5 lg:w-5" /></div>
								<div
									onClick={async () => {
										try {
											if (!player.current) return;

											if (isFullScreen) {
												await document.exitFullscreen();
												setIsFullScreen(false);
											}

											else {
												const video = player.current.getElementsByTagName("video")[0]

												await (video as any).webkitEnterFullScreen();
												setIsFullScreen(true);
											}
										} catch (error: any) {
											alert(error.message)
										}
									}}
									className="cursor-pointer ml-5"
								>
									{
										!isFullScreen ?
											<BsFullscreen color="white" className="h-4 w-4 lg:h-5 lg:w-5" /> :
											<BsFullscreenExit color="white" className="h-4 w-4 lg:h-5 lg:w-5" />
									}
								</div>
							</div>
						</div>
					</div>
				</div>

			</section>
		</main>
	);
}

export default Player1;